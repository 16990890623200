import { default as aboutzEycX1eRuXMeta } from "/tmp/build_78ad9708/pages/about.vue?macro=true";
import { default as indexY6UgKKuTUuMeta } from "/tmp/build_78ad9708/pages/index.vue?macro=true";
import { default as login1DyH6XWYGDMeta } from "/tmp/build_78ad9708/pages/login.vue?macro=true";
import { default as indexB3hZHPmzR3Meta } from "/tmp/build_78ad9708/pages/meetings/[id]/index.vue?macro=true";
import { default as notesNyK66cD1JHMeta } from "/tmp/build_78ad9708/pages/meetings/[id]/notes.vue?macro=true";
import { default as indexSpiMggbpwAMeta } from "/tmp/build_78ad9708/pages/meetings/index.vue?macro=true";
import { default as cancelQ7YPQn5yXDMeta } from "/tmp/build_78ad9708/pages/payment/cancel.vue?macro=true";
import { default as success18s7hVe0Q7Meta } from "/tmp/build_78ad9708/pages/payment/success.vue?macro=true";
import { default as detailsMVsb2SNHMMMeta } from "/tmp/build_78ad9708/pages/pets/[id]/details.vue?macro=true";
import { default as add5c50vly8pmMeta } from "/tmp/build_78ad9708/pages/pets/add.vue?macro=true";
import { default as indexJffV0qCtUfMeta } from "/tmp/build_78ad9708/pages/pets/index.vue?macro=true";
import { default as privacy_45policyF78U7DaOjwMeta } from "/tmp/build_78ad9708/pages/privacy-policy.vue?macro=true";
import { default as indexAChzTpRG8yMeta } from "/tmp/build_78ad9708/pages/profile/index.vue?macro=true";
import { default as signupcV1NZOQAyPMeta } from "/tmp/build_78ad9708/pages/signup.vue?macro=true";
import { default as tos6Vd7LDACZmMeta } from "/tmp/build_78ad9708/pages/tos.vue?macro=true";
import { default as vet_45signuphKNwFmNtITMeta } from "/tmp/build_78ad9708/pages/vet-signup.vue?macro=true";
import { default as indexjGTMqUCQENMeta } from "/tmp/build_78ad9708/pages/vet/meetings/[id]/index.vue?macro=true";
import { default as notesyMEcNXSYAXMeta } from "/tmp/build_78ad9708/pages/vet/meetings/[id]/notes.vue?macro=true";
import { default as indextOMcPrcmiaMeta } from "/tmp/build_78ad9708/pages/vet/meetings/index.vue?macro=true";
import { default as editIrscxTONjqMeta } from "/tmp/build_78ad9708/pages/vet/profile/edit.vue?macro=true";
import { default as indexQslaBRYetJMeta } from "/tmp/build_78ad9708/pages/vet/profile/index.vue?macro=true";
import { default as welcomerlWGubCe0tMeta } from "/tmp/build_78ad9708/pages/vet/welcome.vue?macro=true";
import { default as book_45meetingVlDqolirZ9Meta } from "/tmp/build_78ad9708/pages/veterinarians/[id]/book-meeting.vue?macro=true";
import { default as index9zhZatEXriMeta } from "/tmp/build_78ad9708/pages/veterinarians/index.vue?macro=true";
import { default as welcomeM1RraTlwKwMeta } from "/tmp/build_78ad9708/pages/welcome.vue?macro=true";
export default [
  {
    name: aboutzEycX1eRuXMeta?.name ?? "about",
    path: aboutzEycX1eRuXMeta?.path ?? "/about",
    meta: aboutzEycX1eRuXMeta || {},
    alias: aboutzEycX1eRuXMeta?.alias || [],
    redirect: aboutzEycX1eRuXMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/about.vue").then(m => m.default || m)
  },
  {
    name: indexY6UgKKuTUuMeta?.name ?? "index",
    path: indexY6UgKKuTUuMeta?.path ?? "/",
    meta: indexY6UgKKuTUuMeta || {},
    alias: indexY6UgKKuTUuMeta?.alias || [],
    redirect: indexY6UgKKuTUuMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login1DyH6XWYGDMeta?.name ?? "login",
    path: login1DyH6XWYGDMeta?.path ?? "/login",
    meta: login1DyH6XWYGDMeta || {},
    alias: login1DyH6XWYGDMeta?.alias || [],
    redirect: login1DyH6XWYGDMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexB3hZHPmzR3Meta?.name ?? "meetings-id",
    path: indexB3hZHPmzR3Meta?.path ?? "/meetings/:id()",
    meta: indexB3hZHPmzR3Meta || {},
    alias: indexB3hZHPmzR3Meta?.alias || [],
    redirect: indexB3hZHPmzR3Meta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/meetings/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: notesNyK66cD1JHMeta?.name ?? "meetings-id-notes",
    path: notesNyK66cD1JHMeta?.path ?? "/meetings/:id()/notes",
    meta: notesNyK66cD1JHMeta || {},
    alias: notesNyK66cD1JHMeta?.alias || [],
    redirect: notesNyK66cD1JHMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/meetings/[id]/notes.vue").then(m => m.default || m)
  },
  {
    name: indexSpiMggbpwAMeta?.name ?? "meetings",
    path: indexSpiMggbpwAMeta?.path ?? "/meetings",
    meta: indexSpiMggbpwAMeta || {},
    alias: indexSpiMggbpwAMeta?.alias || [],
    redirect: indexSpiMggbpwAMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/meetings/index.vue").then(m => m.default || m)
  },
  {
    name: cancelQ7YPQn5yXDMeta?.name ?? "payment-cancel",
    path: cancelQ7YPQn5yXDMeta?.path ?? "/payment/cancel",
    meta: cancelQ7YPQn5yXDMeta || {},
    alias: cancelQ7YPQn5yXDMeta?.alias || [],
    redirect: cancelQ7YPQn5yXDMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/payment/cancel.vue").then(m => m.default || m)
  },
  {
    name: success18s7hVe0Q7Meta?.name ?? "payment-success",
    path: success18s7hVe0Q7Meta?.path ?? "/payment/success",
    meta: success18s7hVe0Q7Meta || {},
    alias: success18s7hVe0Q7Meta?.alias || [],
    redirect: success18s7hVe0Q7Meta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: detailsMVsb2SNHMMMeta?.name ?? "pets-id-details",
    path: detailsMVsb2SNHMMMeta?.path ?? "/pets/:id()/details",
    meta: detailsMVsb2SNHMMMeta || {},
    alias: detailsMVsb2SNHMMMeta?.alias || [],
    redirect: detailsMVsb2SNHMMMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/pets/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: add5c50vly8pmMeta?.name ?? "pets-add",
    path: add5c50vly8pmMeta?.path ?? "/pets/add",
    meta: add5c50vly8pmMeta || {},
    alias: add5c50vly8pmMeta?.alias || [],
    redirect: add5c50vly8pmMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/pets/add.vue").then(m => m.default || m)
  },
  {
    name: indexJffV0qCtUfMeta?.name ?? "pets",
    path: indexJffV0qCtUfMeta?.path ?? "/pets",
    meta: indexJffV0qCtUfMeta || {},
    alias: indexJffV0qCtUfMeta?.alias || [],
    redirect: indexJffV0qCtUfMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/pets/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyF78U7DaOjwMeta?.name ?? "privacy-policy",
    path: privacy_45policyF78U7DaOjwMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyF78U7DaOjwMeta || {},
    alias: privacy_45policyF78U7DaOjwMeta?.alias || [],
    redirect: privacy_45policyF78U7DaOjwMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexAChzTpRG8yMeta?.name ?? "profile",
    path: indexAChzTpRG8yMeta?.path ?? "/profile",
    meta: indexAChzTpRG8yMeta || {},
    alias: indexAChzTpRG8yMeta?.alias || [],
    redirect: indexAChzTpRG8yMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: signupcV1NZOQAyPMeta?.name ?? "signup",
    path: signupcV1NZOQAyPMeta?.path ?? "/signup",
    meta: signupcV1NZOQAyPMeta || {},
    alias: signupcV1NZOQAyPMeta?.alias || [],
    redirect: signupcV1NZOQAyPMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: tos6Vd7LDACZmMeta?.name ?? "tos",
    path: tos6Vd7LDACZmMeta?.path ?? "/tos",
    meta: tos6Vd7LDACZmMeta || {},
    alias: tos6Vd7LDACZmMeta?.alias || [],
    redirect: tos6Vd7LDACZmMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/tos.vue").then(m => m.default || m)
  },
  {
    name: vet_45signuphKNwFmNtITMeta?.name ?? "vet-signup",
    path: vet_45signuphKNwFmNtITMeta?.path ?? "/vet-signup",
    meta: vet_45signuphKNwFmNtITMeta || {},
    alias: vet_45signuphKNwFmNtITMeta?.alias || [],
    redirect: vet_45signuphKNwFmNtITMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/vet-signup.vue").then(m => m.default || m)
  },
  {
    name: indexjGTMqUCQENMeta?.name ?? "vet-meetings-id",
    path: indexjGTMqUCQENMeta?.path ?? "/vet/meetings/:id()",
    meta: indexjGTMqUCQENMeta || {},
    alias: indexjGTMqUCQENMeta?.alias || [],
    redirect: indexjGTMqUCQENMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/vet/meetings/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: notesyMEcNXSYAXMeta?.name ?? "vet-meetings-id-notes",
    path: notesyMEcNXSYAXMeta?.path ?? "/vet/meetings/:id()/notes",
    meta: notesyMEcNXSYAXMeta || {},
    alias: notesyMEcNXSYAXMeta?.alias || [],
    redirect: notesyMEcNXSYAXMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/vet/meetings/[id]/notes.vue").then(m => m.default || m)
  },
  {
    name: indextOMcPrcmiaMeta?.name ?? "vet-meetings",
    path: indextOMcPrcmiaMeta?.path ?? "/vet/meetings",
    meta: indextOMcPrcmiaMeta || {},
    alias: indextOMcPrcmiaMeta?.alias || [],
    redirect: indextOMcPrcmiaMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/vet/meetings/index.vue").then(m => m.default || m)
  },
  {
    name: editIrscxTONjqMeta?.name ?? "vet-profile-edit",
    path: editIrscxTONjqMeta?.path ?? "/vet/profile/edit",
    meta: editIrscxTONjqMeta || {},
    alias: editIrscxTONjqMeta?.alias || [],
    redirect: editIrscxTONjqMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/vet/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQslaBRYetJMeta?.name ?? "vet-profile",
    path: indexQslaBRYetJMeta?.path ?? "/vet/profile",
    meta: indexQslaBRYetJMeta || {},
    alias: indexQslaBRYetJMeta?.alias || [],
    redirect: indexQslaBRYetJMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/vet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: welcomerlWGubCe0tMeta?.name ?? "vet-welcome",
    path: welcomerlWGubCe0tMeta?.path ?? "/vet/welcome",
    meta: welcomerlWGubCe0tMeta || {},
    alias: welcomerlWGubCe0tMeta?.alias || [],
    redirect: welcomerlWGubCe0tMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/vet/welcome.vue").then(m => m.default || m)
  },
  {
    name: book_45meetingVlDqolirZ9Meta?.name ?? "veterinarians-id-book-meeting",
    path: book_45meetingVlDqolirZ9Meta?.path ?? "/veterinarians/:id()/book-meeting",
    meta: book_45meetingVlDqolirZ9Meta || {},
    alias: book_45meetingVlDqolirZ9Meta?.alias || [],
    redirect: book_45meetingVlDqolirZ9Meta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/veterinarians/[id]/book-meeting.vue").then(m => m.default || m)
  },
  {
    name: index9zhZatEXriMeta?.name ?? "veterinarians",
    path: index9zhZatEXriMeta?.path ?? "/veterinarians",
    meta: index9zhZatEXriMeta || {},
    alias: index9zhZatEXriMeta?.alias || [],
    redirect: index9zhZatEXriMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/veterinarians/index.vue").then(m => m.default || m)
  },
  {
    name: welcomeM1RraTlwKwMeta?.name ?? "welcome",
    path: welcomeM1RraTlwKwMeta?.path ?? "/welcome",
    meta: welcomeM1RraTlwKwMeta || {},
    alias: welcomeM1RraTlwKwMeta?.alias || [],
    redirect: welcomeM1RraTlwKwMeta?.redirect,
    component: () => import("/tmp/build_78ad9708/pages/welcome.vue").then(m => m.default || m)
  }
]